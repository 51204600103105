import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import classNames from 'classnames';
import { Carousel } from 'ui/components/Carousel';
import Link from 'next/link';
import { PrismicNextImage, PrismicNextLink } from '@prismicio/next';
import ArrowForward from 'ui/svgs/library/ArrowForward';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { DesktopBreakpoint } from 'ui/components/ResponsiveImage';
import styles from './ResourcesCarousel.module.scss';

/**
 * Props for `ResourcesCarousel`.
 */
export type ResourcesCarouselProps = SliceComponentProps<Content.ResourcesCarouselSlice>;

/**
 * Component for "ResourcesCarousel" Slices.
 */
const ResourcesCarousel = ({ slice }: ResourcesCarouselProps): JSX.Element => {
  const { primary, items } = slice;
  const { background_color, title, tag_text } = primary;
  const [bg, setBg] = useState();
  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    }
  }, [])
  const ref = useRef<any>({
    handlePrevious: () => {},
    handleNext: () => {},
  });
  const [scrollState, setScrollState] = useState({
    canScrollNext: false,
    canScrollPrev: false,
  });
  const handleCanScrollState = useCallback(({ canScrollNext = false, canScrollPrev = false }) => {
    setScrollState({ canScrollNext, canScrollPrev });
  }, []);
  const extractData = (
    item: any,
  ): {
    title: string;
    description: string;
    link: string;
    image: any;
    ctaText: string;
    type: string;
  } => {
    switch (item.relational_document.type) {
      case 'case_study_v2': {
        const { data, url } = item.relational_document;
        return {
          title: data.title,
          description: data.description,
          link: url,
          image: data.image,
          ctaText: item.cta_text,
          type: 'Case Study',
        };
      }
      case 'resources': {
        const { data, url } = item.relational_document;
        return {
          title: data.title,
          description: data.description,
          link: url,
          image: data.image,
          ctaText: item.cta_text,
          type: 'Resource',
        };
      }
      case 'blog': {
        const { data, url } = item.relational_document;
        return {
          title: data.title,
          description: data.description,
          link: url,
          image: data.image,
          ctaText: item.cta_text,
          type: 'Article',
        };
      }
      default:
        return {
          title: '',
          description: '',
          link: '',
          image: '',
          ctaText: '',
          type: '',
        };
    }
  };
  const [width] = useWindowsSize();
  // const [progress, setProgress] = useState(0);
  // useEffect(() => {
  //   setInterval(() => {
  //     setProgress(state => (state < items.length ? state + 1 : 0));
  //     ref.current?.handleNext();
  //   }, 4000);
  // }, [items.length]);
  const list = [...(ref.current?.scrollSnapList?.() || [])];
  
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={classNames(styles.root, 'wide-container', styles[bg || background_color], styles[slice.variation])}
      data-background={background_color}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          {width >= DesktopBreakpoint && (
            <div className={styles.actions}>
              {/* <div className={styles.progress}>
                {list.map((item, index) => (
                  <div className={classNames(styles.tile, {})} style={{ width: `${items.length * 10}px` }} key={index}>
                    {index === progress && <div className={styles.done}></div>}
                  </div>
                ))}
              </div> */}
              <div className={styles.buttons}>
                <button
                  className={styles.left}
                  disabled={!scrollState.canScrollPrev}
                  onClick={() => {
                    ref.current?.handlePrevious();
                    // setProgress(state => (state < items.length ? state - 1 : 0));
                  }}
                >
                  <ArrowForward fill="#0f0f0f" />
                </button>
                <button
                  className={styles.right}
                  disabled={!scrollState.canScrollNext}
                  onClick={() => {
                    ref.current?.handleNext();
                    // setProgress(state => (state < items.length ? state + 1 : 0));
                  }}
                >
                  <ArrowForward fill="#0f0f0f" />
                </button>
              </div>
            </div>
          )}
        </div>
        <Carousel
          slideSize={448}
          slideGap="lg"
          withControls={false}
          withIndicators={false}
          autoPlay
          loop
          carouselRef={ref}
          handleCanScrollState={handleCanScrollState}
          breakpoints={[
            {
              maxWidth: 'md',
              slideSize: 330,
              slideGap: 'sm',
            },
            {
              maxWidth: 'md',
              slideSize: 366,
              slideGap: 'sm',
            },
          ]}
        >
          {items.map((item, index) => {
            return (
              <Carousel.Slide key={index}>
                <PostSlide {...extractData(item)} />
              </Carousel.Slide>
            );
          })}
        </Carousel>

        {width < DesktopBreakpoint && (
          <div className={styles.header}>
            <div className={styles.actions}>
              {/* <div className={styles.progress}>
                {list.map((item, index) => (
                  <div className={classNames(styles.tile, {})} style={{ width: `${items.length * 10}px` }} key={index}>
                    {index < progress && <div className={styles.done}></div>}
                  </div>
                ))}
              </div> */}
              <div className={styles.buttons}>
                <button
                  className={styles.left}
                  disabled={!scrollState.canScrollPrev}
                  onClick={() => {
                    ref.current?.handlePrevious();
                    // setProgress(state => (state < items.length ? state - 1 : 0));
                  }}
                >
                  <ArrowForward fill="#0f0f0f" />
                </button>
                <button
                  className={styles.right}
                  disabled={!scrollState.canScrollNext}
                  onClick={() => {
                    ref.current?.handleNext();
                    // setProgress(state => (state < items.length ? state + 1 : 0));
                  }}
                >
                  <ArrowForward fill="#0f0f0f" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export const PostSlide = ({ title, description, link, image, ctaText, type }) => {
  return (
    <Link href={link} className={styles.card}>
      <div className={styles.imgWrapper}>
        <PrismicNextImage field={image} />
      </div>
      <span className={styles.type}>{type}</span>
      <div className={styles.title}>{title}</div>
      <div className={styles.bottom}>
        <div className={styles.descWrapper}>
          <div className={styles.description}>{description}</div>
        </div>
        <div className={styles.link}>
          {ctaText} <ArrowForward fill="#2F6AE3" />{' '}
        </div>
      </div>
    </Link>
  );
};
export default ResourcesCarousel;
